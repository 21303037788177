<!--
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:43:57
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2023-05-11 17:10:06
-->
<template>
  <div class="login_bck">
    <div class="login_top"></div>
    <div class="login_content" v-if="schoolList.length == 0">
      <div class="title_1">欢迎登录</div>
      <el-tabs v-model="activeName" style="padding: 0 10%; box-sizing: border-box">
        <el-tab-pane label="账户密码" name="first">
          <tabsPsd @selectsc="onSelectSc" />
        </el-tab-pane>
      </el-tabs>
      <div class="regest"></div>
    </div>
    <div class="login_selectsc" v-else>
      <div class="dev_back" @click="goBack">
        <img src="../../../../assets/img/Chevron-left_minor.png" v-if="!backStyleType"/>
        <img src="../../../../assets/img/icon_left_true.png" v-else="backStyleType">
        <p  @mouseover="backMouseOver"  @mouseleave="backMouseLeave" :style="backSty">返回上一级</p>
      </div>
      <div class="dev_top">
        <!-- <img src="../../../../assets/img/curriculum_left_jiantou.png" /> -->
        <p class="title">选择学校</p>
      </div>
      <p class="message">选择您要管理的学校名称</p>
      <div style="margin: 32px 38px 0 38px; height: 180px; overflow-y: auto;   padding-right: 8px;">
        <div v-for="item in schoolList" :class="['item',schoolId == item.schoolId ? 'item_sty' : 'item_sty1']" 
          @click="onClickSelectSc(item)"  @mouseover="mouseOver(item)"  @mouseleave="mouseLeave(item)" :style="item.item_sty">
          <div class="item_name">
            <img class="icon" src='../../../../assets/img/icon_school.png' />
            <P class="text_schoolname">{{ item.schoolName }}</P>
          </div>
          <img class="icon_right" src='../../../../assets/img/icon_right.png' />
        </div>
      </div>
      <!-- <p class="text_login" @click="onLogin">登录</p> -->
    </div>
    <div class="footer" style="user-select: none">
      <div>京ICP备17066301号</div>
    </div>
  </div>

</template>

<script>
// import Verify from "../../components/verifition/Verify";
import md5 from "md5";
import login from "./js/login";
export default {
  ...login,
};
</script>
<style lang="less" scoped>
@import "./css/login.less";
</style>
