<!--
 * @Author: 
 * @Date: 2023-05-23 11:50:46
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-05-24 11:57:06
 * @Description: 
-->
<template>
  <Modeldialog
    ref="global_upload_error"
    @sure="downloadErrorTemplate"
    @close="importErrorDialogShow = false"
    :buttonShow="true"
    :height="'300px'"
    :width="'500px'"
    sureButtonText="下载错误模板"
  >
    <template #contents>
      <div class="error_template_dialog">
        <p class="p1">导入完成！</p>
        <p class="p2">已将不符合规范的记录生成错误模板，请按规范重新填写！</p>
      </div>
    </template>
  </Modeldialog>
</template>
<script>
export default {
  props: ["errorTemplateUrl"],
  data() {
    return {
      importErrorDialogShow: false,
      fileName: "",
    };
  },
  methods: {
    // 下载错误模板
    downloadErrorTemplate() {
      this.$utils.downloadFile(this.fileName, this.errorTemplateUrl);
    },
  },
  watch: {
    importErrorDialogShow(n, o) {
      if (n) {
        this.$refs.global_upload_error.title = "提示";
        this.$refs.global_upload_error.OpenDialog();
      } else {
        this.$refs.global_upload_error.title = "";
        this.$refs.global_upload_error.CloseDialog();
      }
    },
  },
};
</script>
<style scoped lang="less">
.error_template_dialog {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: space-around;
  .p1 {
    font-size: 16px;
  }
  .p2 {
    font-size: 14px;
  }
}
</style>
