import axios from "axios";
import qs from "qs";
import Vue from "vue";
import { ElMessage } from "element-plus";

var _CODE_STATUS_ = {
  CODE_UNKNOWN: 4001,
  CODE_OK: 200,
  CODE_OKT: 3000,
  CODE_NO_DATA: 1,
  CODE_PARA_ERROR: 2,
  CODE_CHILD_ERROR: 3,
  CODE_ERROR: 400,
  CODE_LOGIN_REQUIRE: 11,
  CODE_NO_RIGHT: 12,
  CODE_NEED_JUMP: 13,
  CODE_INTER_ERR: 14,
  CODE_REPEAT:51001
};
const instance = axios.create({ baseURL: process.env.VUE_APP_API_BASEURL });
var isOpenLogin = false;
function ajax() {
  var options = {
    method: "post",
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=UTF8",
      "X-Requested-With": "XMLHttpRequest",
    },
    data: {},
  };
  var successHandler = function (code, msg, data, resolve) {
      resolve([code, msg, data]);
    },
    loginHandler = function (c, m, d) {
      if (isOpenLogin) return;
      isOpenLogin = true;
      window.location.href = "";
    },
    msgHandler = function (c, m, d) {
      ElMessage.info(m);
    },
    jumpHandler = function (c, m, d) {
      // console.log(1);
      // console.log("此处提示信息");
    },
    errorHandler = function (c, m, d) {
      ElMessage.error(m);
    },
    then = function (responseMsg, resolve) {
      // console.log(responseMsg);
      if (responseMsg) {
        let stutas = responseMsg.status;
        switch (stutas) {
          case 200:
            let resData = responseMsg.data;
            let code = resData.code;
            let data = resData.result;
            let msg = resData.describe;
            switch (code) {
              case _CODE_STATUS_.CODE_OK:
              case _CODE_STATUS_.CODE_OKT:
              case _CODE_STATUS_.CODE_NO_DATA:
              case _CODE_STATUS_.CODE_PARA_ERROR:
                successHandler(code, msg, data, resolve);
                break;
              case _CODE_STATUS_.CODE_ERROR:
              case _CODE_STATUS_.CODE_UNKNOWN:
              case _CODE_STATUS_.CODE_REPEAT:
                // case _CODE_STATUS_.CODE_PARA_ERROR:
                errorHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_LOGIN_REQUIRE:
                loginHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NEED_JUMP:
                jumpHandler(code, msg, data);
                break;
              case _CODE_STATUS_.CODE_NO_RIGHT:
              case _CODE_STATUS_.CODE_CHILD_ERROR:
              case _CODE_STATUS_.CODE_INTER_ERR:
                msgHandler(code, msg, data);
                break;
            }
            break;
        }
      }
    };
  this.post = function (config) {
    // 请求数据
    let config_ = options;
    Object.assign(config_, options, config);
    if (!config_.url) {
      // console.log("url can not be null");
      return null;
    }
    try {
      if (config_.method.toLowerCase() == "get") {
        config_.params = config_.data;
      } else if (
        config_.method.toLowerCase() == "post" &&
        config_.headers["content-type"].indexOf(
          "application/x-www-form-urlencoded"
        ) >= 0
      ) {
        config_.data = qs.stringify(config_.data);
      }
      return new Promise(function (resolve, reject) {
        return instance(config_)
          .then((res) => {
            then(res, resolve);
          })
          .catch((res) => {
            then(res, resolve);
          });
      });
    } catch (e) {
      return null;
    }
  };
  this.success = function (fn) {
    successHandler = fn;
    return this;
  };
  this.error = function (fn) {
    errorHandler = fn;
    return this;
  };
  this.login = function (fn) {
    loginHandler = fn;
    return this;
  };
  this.jump = function (fn) {
    jumpHandler = fn;
    return this;
  };
  this.msg = function (fn) {
    msgHandler = fn;
    return this;
  };
}
export const ajaxHandler = ajax;
export default {
  ajaxHandler() {
    return new ajax();
  },
  ajax: function (config) {
    return new ajax().post(config);
  },
};
// example
/**
 * ajaxHandler().success(function(c,m,d){// your code here...}).post({});
 * ajaxHandler().post({}).then(res = > {c==res[0],m==res[1],d==res[2]});
 *
 * ajax({}).then(res = > {c==res[0],m==res[1],d==res[2]});
 * */
