import Vue from 'vue';
import treeSelect from "@/components/tree/tree";
import VueUeditorWrap from '@/components/ueditor/vue-ueditor-wrap';
import UploadFile from '@/components/UploadFile/UploadFile';
const defaultConfig = {
  labelWidth: '60px',
  labelPosition: 'left',
}
// 表单字段格式化
const RenderContent = {
  props: {
    render: Function,
    formatter: Function, // 格式化数据
    data: Object,
    prop: String
  },
  render (h) {
    if (this.render) {
      return this.render(h, this.data)
    }
    let value = this.data[this.prop]
    if (this.formatter) {
      value = this.formatter(value, this.data)
    }
    return <span>{value}</span>
  }
}
// element UI 组件
const alias = {
  input: {
    component: 'el-input',
    props: {}
  },
  number: {
    component: 'el-input-number',
    props: {
      type: 'number',
      precision:2,
      step:1,
      min:0,
      // max:100
    }
  },
  textarea: {
    component: 'el-input',
    props: {
      type: 'textarea'
    }
  },
  radio: {
    component: 'el-radio',
    props: {}
  },
  checkbox: {
    component: 'el-checkbox',
    props: {}
  },
  label: {
    component: 'render-content',
    props: {}
  },
  select: {
    component: 'el-select',
    props: {}
  },
  datePicker: {
    component: 'el-date-picker',
    props: {
      style: {
        width: '100%'
      },
      type:'date',
      "value-format": 'YYYY-MM-DD'
    }
  },
  timeSelect: {
    component: 'el-time-select',
    props: {
      "format": 'HH:mm',
    }
  },
  timePicker: {
    component: 'el-time-picker',
    props: {
      "format": 'HH:mm:ss',
    }
  },
  dateTimePicker:{
    component: 'el-date-picker',
    props: {
      type:'datetime',
      "value-format": 'YYYY-MM-DD hh:mm:ss',
    }
  },
  switch:{
    component: 'el-switch',
    props: {
      activeColor:"#13ce66",
      inactiveColor:"#ff4949",
      activeValue:"100",
      inactiveValue:"0",
    }
  },
  upload:{
    component: 'el-upload',
    props: {

    }
  },
  tree:{
    component: 'el-tree',
    props: {

    }
  },
  percentage:{
    component: 'el-progress',
    props: {

    }
  },
  fulltext:{
    component: 'el-fulltext',
    props: {
    }
  },
}

export default {
  data() {
    return {
      dialogTreeShow:false,
      form:{},
      treeCurrent:{},
      treeDataList:[],
      parentId:'',
      parentName:'',
      QualificationInput:false,
      fileList:[],
    }
  },
  components: {
    RenderContent,treeSelect,VueUeditorWrap,UploadFile
  },
  props: {
    formConfig: Object,
    formItems: Array,
    data: Object
  },
  beforeMount:function(){
    let this_=this;
    this.formItems.forEach(function(item,i){
      if(!!item.datasource){
        let type = item.datasource.substr(0,item.datasource.indexOf('_'));
        let typeData = item.datasource.substr(item.datasource.indexOf('_')+1,item.datasource.length)
        if(type=='basecode'){
          this_.initBaseCode( '/system/conmonData/queryDataNoCache','basecode',typeData,function(data){
            item.dataList = data;
          })
        }else if(type=='sql'){
          this_.initBaseCode( '/system/conmonData/queryDataNoCache',typeData,'',function(data){
            item.dataList = data;
          })
        }else if(type=='url'){
          this_.initBaseCode(typeData,'','',function(data){
            item.dataList = data;
          })
        }
      }
    });

  },
  computed: {
    computedConfig () {
      return {
        ...defaultConfig,
        ...this.formConfig
      }
    },
    computedItems () {
      const itemResult = []
      for (const item of this.formItems) {
        // 剩余参数语法允许我们将一个不定数量的参数表示为一个数组。theArgs
        let {component, showIf, prop, props,options,datasource,dataList, ...theArgs} = item
        if (typeof showIf === 'function' && !showIf(this.data)) {
          continue
        }
        if (typeof props === 'function') {
          props = props(this.data)
        }
        if (typeof options === 'function') {
          // console.log(options)
        }
        if (component === 'label') {
          props = {
            ...props,
            data: this.data,
            prop
          }
        }
        if (alias[component]) {
          props = {
            ...alias[component].props,
            ...props
          }
          component = alias[component].component
        }
        itemResult.push({
          component,
          prop,
          dataList,
          props,
          datasource,
          options,
          ...theArgs
        })
      }
      return itemResult
    },
    rules () {
      let rules = this.computedItems.reduce((total, item) => {
        if (item.rules) {
        let rules = item.rules
        if (typeof rules === 'function') {
          rules = rules(this.data)
        }
        total[item.prop] = rules
      }
      return total
    }, {})
      return rules
    }
  },
  methods: {
    async initBaseCode(url,queryId,typeData,callBack){
  let this_=this;
  let params ={}
  if(!!queryId){
    params.queryId = queryId;
  }
  if(!!typeData){
    params.param = typeData;
  }
  this.http.ajaxHandler().success(function (code, msg, data) {
    if(code==200){
      if(!!callBack){
        callBack(data)
      }
    }
  }).error(function (code, msg) {
    this_.$message.error(msg);
  }).post({url:url,data:params});
},
closeTreedialog(){
  this.dialogTreeShow = false;
},
saveTree(data){
  this.data[this.treeCurrent.prop] = data.name;
  this.data[this.treeCurrent.prop+'Id'] = data.id;
  this.parentId = data.id;
  this.parentName = data.name;
  this.dialogTreeShow = false;
},
freshFileList(list,id){
  this.data[id.substr(7,id.length)]=list;
},
dialogTreeFocus(item){
  if(!!item.dataList){
    this.treeDataList = item.dataList;
  }
  this.treeCurrent = item;
  this.dialogTreeShow = true;
},
inputTextFull(content,id){
  this.data[id.substr(7,id.length)]=content;
},
getItemKey (item, index) {
  return `${item.prop}-${index}`
},
validate () {
  return new Promise(resolve => {
    this.$refs.form.validate(resolve)
  })
},
clearValidate (props) { // 清空校验
  this.$refs.form.clearValidate(props)
}
}
}
