/*
 * @Author: yuan wei lin
 * @Date: 2022-10-09 13:46:47
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-08-15 15:55:40
 * @Descripttion:
 */

// import Storage from "@/commons/utils/storage.js";
// import Vue from 'vue';
import { createApp } from "vue";
import App from "../App.vue";
const app = createApp(App);
export default (router) => {
  let routes = router.options.routes;
  router.beforeEach((to, from, next) => {
    // if((Storage.get("isMenu")&&Storage.get("isMenu")=="yes")){
    //   next();
    // }else{
    // console.log(to);
    // return;

    let flag = false;
    routes.forEach(function (item) {
      if (item.path == to.path) {
        flag = true;
      } else if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          if (child.path != to.path) return;
          app.config.globalProperties.currentTabName = child.tabName;
          flag = true;
        });
      }
    });
    if (flag) next();
    else window.location.href = app.config.globalProperties.PRODUCT_PATH;
    // }
  });
};
// export const addRouterHandler = (router, appendRouter) => {
// router.options.routes.forEach(item=>{
//   item.beforeRouteEnter=function (to, from, next){
//     // console.log(next)
//     next(vm=>{
//       // console.log(vm);
//     });
//   };
// });
// router.addRoutes(router.options.routes);
// };
