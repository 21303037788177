import forge from "node-forge";

// RSA-公钥
const RSA_PUBLIC_KEY_ENCODING =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAna/NozJBKFQX0U6S1mr17Ohaus3Rhs+6c5j/0SvFXpp0kBLC559v8TkHmBYIHUeXpG54u/LBU3ee5VJAnJFqZ7z6lF/ZF8eyIbh68y+8qQ+wG1pXiuuW/mdDUE/U4+yEOd5ZAKeHjhJLaOv+XplYlu9MeayJXcuqukgyDPlB16tvSQyCtkk1IZfm/CLveR8agQKtW8k9zLb/BWi4DC7aRqfbtCTkaf1+FZdJrcjBVLRjwqoN7osy7wFTsxcvqt1JiU7Y0o+Ddhx4e0OfRYzM4vw/K5h33lXeZLshE5p174YZg3Zr23f+a4yz2Jb2mGmqrvJsHInq3kFf0K9K+LPYFQIDAQAB";
// base64解码为字节
const publicKeyBytes = forge.util.decode64(RSA_PUBLIC_KEY_ENCODING);
// 转换为ASN.1结构
const publicKeyAsn1 = forge.asn1.fromDer(publicKeyBytes);
// 得到forge rsa 对象
const publicKey = forge.pki.publicKeyFromAsn1(publicKeyAsn1);

/**
 * 获取秒单位时间戳
 */
export function getTs() {
  return Math.floor(Date.now() / 1000);
}

/**
 * 生成256位的aeskey，返回base64编码后去掉最后一个等号的字符串
 */
export function generateEncodingAesKey() {
  const aesKey = forge.random.getBytesSync(32);
  // base64编码的aeskey
  const encodingAesKey = forge.util.encode64(aesKey);
  // 去掉最后一个等号
  return encodingAesKey.substring(0, encodingAesKey.length - 1);
}

/**
 * 获取16-32字节非固定长度随机base64字符串
 **/
export function getNonce() {
  const len = Math.floor(Math.random() * 17) + 16;
  const nonceBytes = forge.random.getBytesSync(len);
  return forge.util.encode64(nonceBytes).replace(/=/g, "");
}

/**
 * 使用rsa公钥对明文进行加密
 * @param data 明文字符串
 * @param ts   单位为秒的时间戳，header值
 */
export function encryptByRsa(data, ts) {
  const nonce = getNonce();
  let plaintJson = {
    _n: nonce,
    data: data,
    _timestamp: ts,
  };
  const plaintJsonStr = JSON.stringify(plaintJson);
  // 明文转换位字节
  const buffer = forge.util.createBuffer(plaintJsonStr, "utf8");
  var encrypted = publicKey.encrypt(buffer.getBytes(), "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });

  return forge.util.encode64(encrypted);
}

/**
 * aes加密
 *
 * @param ts           时间戳，单位秒，请求头/响应头参数
 * @param aadNonce     GCM分组模式拼接需要的随机字符串，请求头/响应头参数
 * @param originalData 实际数据明文
 * @param aesKeyStr    aes加密key
 * @return 加密后的数据结构
 **/
export function encryptByAes(ts, aadNonce, originalData, aesKeyStr) {
  // GCM分组模式需要的 额外认证数据（AAD）
  const aad = ts + "|" + aadNonce;
  // 初始向量，12字节随机字符串
  const realIv = forge.random.getBytesSync(12);
  // 额外认证数据转换为字节
  const realAad = forge.util.createBuffer(aad, "utf-8").getBytes();

  // 非固定长度随机字符串，用作实际数据中的安全字段
  const nonce = getNonce();
  // 原始明文添加时间戳、随机字符串作为安全字段
  let plaintJson = {
    data: originalData,
    // 安全字段首字符均为下划线，与参数字段相互独立
    _n: nonce,
    _timestamp: ts,
  };
  // 原始明文转换为字节
  const plaintJsonStr = JSON.stringify(plaintJson);
  const realPlaintextBytes = forge.util.encodeUtf8(plaintJsonStr);

  // 设置加密模式为AES的GCM模式
  const aesKey = forge.util.decode64(aesKeyStr + "=");
  const cipher = forge.cipher.createCipher("AES-GCM", aesKey);
  cipher.start({
    iv: realIv,
    additionalData: realAad,
    tagLength: 128,
  });
  cipher.update(forge.util.createBuffer(realPlaintextBytes));
  cipher.finish();

  return {
    iv: forge.util.encode64(realIv),
    data: forge.util.encode64(cipher.output.getBytes()),
    authTag: forge.util.encode64(cipher.mode.tag.getBytes()),
  };
}

/**
 * aes解密
 *
 * @param ts               时间戳，单位秒，请求头/响应头参数
 * @param aadNonce         GCM分组模式拼接需要的随机字符串，请求头/响应头参数
 * @param encryptionResult 加密结果
 * @param aesKeyStr        aes加密key
 * @return 解密后的实际数据明文
 **/

export function decryptByAes(ts, aadNonce, encryptionResult, aesKeyStr) {
  const iv = forge.util.decode64(encryptionResult.iv);
  const data = forge.util.decode64(encryptionResult.data);
  const authTag = forge.util.decode64(encryptionResult.authTag);

  // GCM分组模式需要的 额外认证数据（AAD）
  const aad = ts + "|" + aadNonce;
  // 额外认证数据转换为字节
  const realAad = forge.util.createBuffer(aad, "utf-8").getBytes();

  // 设置加密模式为AES的GCM模式
  const aesKey = forge.util.decode64(aesKeyStr + "=");
  const tag = forge.util.createBuffer(authTag);
  const decipher = forge.cipher.createDecipher("AES-GCM", aesKey);
  decipher.start({
    iv: iv,
    additionalData: realAad,
    tagLength: 128,
    tag: tag,
  });
  decipher.update(forge.util.createBuffer(data));
  let pass = decipher.finish();
  // 解密失败
  if (!pass) {
    console.error("解密失败");
    return null;
  }

  // 解密成功
  const resultJsonStr = decipher.output.toString();
  const resultJson = JSON.parse(resultJsonStr);
  return resultJson.data;
}

// 请求加密
export function getEncryptionParams(params) {
  let ts = this.getTs();
  let nonce = this.getNonce();
  let aesKeyStr = this.generateEncodingAesKey();
  let encryptionAesKey = this.encryptByRsa(aesKeyStr, ts);
  let encryptionParam = this.encryptByAes(ts, nonce, params, aesKeyStr);
  return {
    aesKeyStr,
    ts,
    nonce,
    headers: {
      "Houdu-Ts": ts,
      "Houdu-Nonce": nonce,
      "Houdu-Key": encryptionAesKey,
    },
    data: encryptionParam,
  };
}

// 返回参数解密
export function getDecryptParams(params, headers, response) {
  let { aesKeyStr } = params;
  let descryptionResponse = decryptByAes(
    headers["houdu-ts"],
    headers["houdu-nonce"],
    response,
    aesKeyStr
  );
  return JSON.parse(descryptionResponse);
}

// /*----------------以下为参数加密，响应解密流程示例----------------*/
// let ts = getTs();
// let nonce = getNonce();
// let aesKeyStr = generateEncodingAesKey();
// console.log(aesKeyStr);

// const encryptionAesKey = encryptByRsa(aesKeyStr, ts);
// // 请求头
// let headers = {
//   "Houdu-Ts": ts,
//   "Houdu-Nonce": nonce,
//   "Houdu-Key": encryptionAesKey,
// };
// console.log(headers);

// // 明文参数
// const param = {
//   stuId: "123245346a",
//   stuName: "张三",
// };
// // const param = '187618605';
// // 加密参数
// const encryptionParam = encryptByAes(ts, nonce, param, aesKeyStr);
// console.log(encryptionParam);

// ts = 1708942660;
// nonce = "MvOjzJbbsSCdm8OME84XC+qzNkaT6qQXIsuv7aW9";
// aesKeyStr = "AxheGYgW/+FJpeEMg40udUjNgsvP/X4/3jcDRXFkSkk";
// // 响应头
// headers = {
//   "Houdu-Ts": ts,
//   "Houdu-Nonce": nonce,
//   "Houdu-Key": encryptionAesKey,
// };
// // 加密的响应数据
// const response = {
//   iv: "5CDtByiefMJ1T4u8",
//   data: "ako3yMunMJ9YM/7N47zJQngJInFs4edvKafjL71Bi/7YTXGqYUnJPsmJi/1LyDQ4ZzkDxYYF4btzs0GhffpjixyYh1Gbm/XDLldXaqNhHZb+4KuqKinG2mZL0cMyYgChxXelHujUkpa7ULDgBRaK8HGBeuqwjA==",
//   authTag: "CgT5HvVqmUh0z6Wn6OkvNw==",
// };

// // 解密响应结果
// const descryptionResponse = decryptByAes(headers["Houdu-Ts"], headers["Houdu-Nonce"], response, aesKeyStr);
// console.log(descryptionResponse);
