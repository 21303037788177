<!--
 * @Author: 
 * @Date: 2022-11-11 18:43:50
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-08-18 15:14:38
 * @Description: 
-->
<template>
  <div class="model_dialog" v-if="show">
    <div class="content" :style="{
      width: width || '480px',
      minWidth: width || '480px',
      height: height || '232px',
      minHeight: height || '232px',
      overflow: 'hidden',
    }">
      <div class="title">
        <p>{{ title || "提示" }}</p>
        <!-- <el-icon @click="close">
          <Close />
        </el-icon> -->
      </div>
      <div class="main_content global_scroll_bar" :class="{
        buttonShowFalse: !buttonShow,
        buttonShowTrue: buttonShow,
      }">
        <!-- 组件页面 -->
        <slot name="contents"></slot>
        <!-- 调用案例 -->
        <!-- <template v-slot:content> </template> -->
      </div>
      <!-- <div class="main_button_box2" v-show="buttonShow"></div> -->
      <div class="main_button_box" v-show="buttonShow">
        <div class="tv_cancle" @click="close">
          <p>取消</p>
        </div>
        <div class="tv_sure" @click="Sure">
          <p>{{ sureButtonText || "确认" }}</p>
        </div>
        <div class="sure_button" v-if="continueButton" @click="continueSure">
          <p>保存并继续</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["buttonShow", "height", "width", "continueButton", "sureButtonText"],
  data() {
    return {
      show: false,
      title: "",
      buttonShow: this.buttonShow,
    };
  },
  methods: {
    // 打开弹窗
    OpenDialog() {
      this.show = true;
    },

    // 关闭弹窗
    CloseDialog() {
      this.show = false;
    },

    // 取消按钮
    close() {
      this.$emit("close");
    },

    // 确认按钮
    Sure() {
      this.$emit("sure");
    },

    // 继续保存按钮--只用于班级信息维护中,新增弹窗
    continueSure() {
      this.$emit("continueSure");
    },
  },
};
</script>
<style scoped lang="less">
.buttonShowTrue {
  height: calc(100% - 25px - 80px);
  // overflow-y: auto;
}

.buttonShowFalse {
  height: calc(100% - 40px);
  // overflow-y: auto;
}

.model_dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 4;

  .content {
    background-color: #fff;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    >div {
      box-sizing: border-box;
    }

    .title {
      user-select: none;
      width: 100%;
      color: #333;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 28px;
      padding: 16px 24px;
      box-shadow: 0px -1px 0px 0px #DEDEDE inset;
      position: relative;

      /deep/ .el-icon {
        cursor: pointer;
        position: absolute;
        right: 20px;
        font-size: 18px;
      }

      /deep/ .el-icon:hover,
      .el-icon:active {
        color: rgb(36, 162, 226);
      }
    }

    .main_button_box2 {
      width: 100%;
      height: 70px;
    }

    .main_button_box {
      width: 100%;
      display: flex;
      position: absolute;
      bottom: 0;
      justify-content: end;
      box-shadow: 0px 0.5px 0px 0px #DEDEDE inset;
      padding: 16px 24px;
      box-sizing: border-box;

      .close_buttons {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 74px;
        height: 36px;
        border: 1px solid #c1c1c1;
        border-radius: 24px;
        color: #212121;
        font-size: 14px;
        padding: 8px 16px;
        box-sizing: border-box;
      }

      .tv_cancle {
        cursor: pointer;
        padding: 8px 16px;
        color: #333;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px;
        border: 1px solid #DEDEDE;
        background: #FFF;
      }

      .tv_sure {
        cursor: pointer;
        border-radius: 8px;
        background: #09B189;
        padding: 8px 16px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px;
        margin-left: 16px;
      }

      .sure_buttons {
        cursor: pointer;
        margin-left: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 74px;
        height: 36px;
        background: @unify-color;
        border-radius: 24px;
        color: #fff;
        font-size: 14px;
        padding: 8px 16px;
        box-sizing: border-box;
      }
    }
  }
}</style>
