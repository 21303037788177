<!--
 * @Author: yuan wei lin
 * @Date: 2022-12-05 19:38:30
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-01-09 18:53:00
 * @Descripttion: 
-->
<template>
  <div class="main_separate_content_single_row_button">
    <el-button
      :data-url="paramsUrl"
      type="table_create"
      round
      @click="ExportTable"
      >导出</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      paramsUrl: "",
    };
  },
  methods: {
    ExportTable() {
      this.$emit("exportFile");
    },
    export({ url, params, method, name }) {
      this.paramsUrl = url;

      this.$axios({
        method,
        url,
        data: params,
        headers: {
          responseType: "blob",
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.$utils.downloadFile(name, res.result);
            // location.href = process.env.VUE_APP_FILE_URL + res.result;
            // let blob = new Blob([res.result], {
            //   type: "application/vnd.ms-excel",
            // });
            // let objectUrl = URL.createObjectURL(blob);
            // let a = document.createElement("a");
            // a.href = objectUrl;
            // a.download = name || '未命名文件';
            // a.dispatchEvent(
            //   new MouseEvent("click", {
            //     bubbles: true,
            //     cancelable: true,
            //     view: window,
            //   })
            // );
            // window.URL.revokeObjectURL(blob);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
