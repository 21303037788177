<template>
  <div>
    <el-dialog
      v-model="dialogVisibeImport"
      :show-close="false"
      :title="title"
      width="640"
    >
      <div class="export_base">
        <div class="download_base">
          <span class="title">下载模版</span>
          <div class="download_date">
            <div style="display: flex">
              <img src="@/assets/img/Excel-xls_major.png" />
              <div class="message">
                <sapn class="tit">下载{{ title }}模板</sapn>
                <sapn class="tit2"
                  >文件大小不得超过30M，支持格式：XLS / XLSX</sapn
                >
              </div>
            </div>
            <span class="button_download" @click="downloadTemplate"
              >下载导入模版</span
            >
          </div>
        </div>
        <div class="line"></div>
        <div class="upload_base">
          <span class="title">上传模版</span>
          <div @click="import_file" class="upload_date">
            <img src="@/assets/img/Importing-files_minor.png" />
            <sapn class="message">{{
              fileName ||
              "下载模板并完善信息后，可直接将文件拖拽至此处上传，支持格式：XLS / XLSX"
            }}</sapn>
            <span class="button_upload" v-if="!fileName">上传文件</span>
            <span class="button_upload_rad" v-if="fileName">重新上传</span>
          </div>
        </div>
        <div class="dailog-model" v-if="loadingShow">
          <img src="../assets/img/0ba928a5dd454cdd937807d2981725d2.gif" />
        </div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <my-button @click="clseDialog">取消</my-button>
          <my-button type="primary" @click="confirmImport">导入</my-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="importErrorDialogShow"
      :show-close="false"
      title="提示"
      width="640"
    >
      <div class="export_base">
        <div class="download_base">
          <span class="title">{{ importDescribe }}</span>
          <span
            v-if="sureButtonText != '确定'"
            class="title_red margin-top-8 margin-bottom-8"
            >已将不符合规范的记录生成错误模板，请按规范重新填写！</span
          >
          <div class="download_date">
            <div style="display: flex">
              <img src="@/assets/img/Excel-xls_major.png" />
              <div class="message">
                <sapn class="tit">{{ fileName }}</sapn>
                <sapn class="tit2">已完成导入</sapn>
              </div>
            </div>
            <el-progress
              type="circle"
              :percentage="100"
              :color="colors"
              :stroke-width="8"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <my-button @click="importErrorDialogShow = false">取消</my-button>
          <my-button type="primary" @click="downloadErrorTemplate">{{
            sureButtonText
          }}</my-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    uploadShow: {
      type: Boolean,
      required: false,
    },
    title: {
      type: String,
      required: "",
    },
    apiModel: {
      type: String,
      required: "",
    },
    apiBusiness: {
      type: String,
      required: "",
    },
    importApi: {
      type: String,
      required: "",
    },
  },
  data() {
    return {
      sureButtonText: "",
      loadingShow: false,
      fileName: "",
      importImageUrl: "",
      fileFormData: null,
      dialogVisibeImport: false,
      importErrorDialogShow: false,
      importDescribe: "",
      errorTemplateUrl: "",
    };
  },

  methods: {
    confirmImport() {
      this.$axios({
        method: "post",
        url: this.importApi,
        data: this.fileFormData,
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("导入成功");
          this.dialogVisibeImport = false;
          this.importDescribe = "导入成功";
          this.importErrorDialogShow = true;
          this.$emit("refreshTable");
          this.sureButtonText = "确定";
        } else if (res.code == 3000) {
          this.dialogVisibeImport = false;
          this.importDescribe = "导入完成";
          this.importErrorDialogShow = true;
          this.errorTemplateUrl = res.data;
          this.sureButtonText = "下载错误模版";
        } else this.$message.error(res.describe);
      });
    },

    // 下载错误模板
    downloadErrorTemplate() {
      if (this.sureButtonText != "确定") {
        this.$utils.downloadFile(
          `${this.title}错误记录模板`,
          this.errorTemplateUrl
        );
      } else this.importErrorDialogShow = false;
    },

    import_file() {
      this.$utils.UploadInputFile({
        callback: (file) => {
          let type = file.name.split(".")[1];
          if (type == "xls" || type == "xlsx" || type == "et") {
            let formData = new FormData();
            this.fileName = file.name;
            formData.append("file", file);
            this.fileFormData = formData;
            this.$message.success("上传成功");
            this.importImageUrl = 2;
          } else this.$message.error("请上传正确的文件类型");
        },
      });
    },

    // 下载模板
    downloadTemplate() {
      this.loadingShow = true;
      this.$axios({
        method: "get",
        url: `/file/template/getPathByModel?model=${this.apiModel}&business=${this.apiBusiness}`,
      }).then((res) => {
        setTimeout(() => {
          this.loadingShow = false;
          if (res.code == 200) {
            this.$utils.downloadFile(`${this.title}模板`, res.result);
          } else this.$utils.messageText(res);
        }, 1000);
      });
    },

    clseDialog() {
      this.$parent.uploadShow = false;
    },
  },
  watch: {
    importErrorDialogShow(n, o) {
      if (!n) this.clseDialog();
    },
  },
  mounted() {
    this.dialogVisibeImport = this.uploadShow;
  },
};
</script>
<style scoped lang="less">
::v-deep .el-dialog__body {
  padding: 0 !important;
}
:deep(.el-progress) {
  width: 44px;
  height: 44px;

  .el-progress-circle {
    width: 100% !important;
    height: 100% !important;
  }
}

:deep(.el-progress__text) {
  font-size: 10px !important;
  min-width: 0px;
  color: #09b189;
}

.export_base {
  padding: 24px;
  position: relative;
  .title {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .title_red {
    color: #ff3b30;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  img {
    width: 48px;
    height: 48px;
  }

  .download_base {
    display: flex;
    flex-direction: column;

    .download_date {
      margin-top: 16px;
      border-radius: 8px;
      border: 1px solid #dedede;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .message {
        display: flex;
        margin-left: 16px;
        margin-right: 16px;
        flex-direction: column;
        justify-content: space-between;

        .tit {
          color: #333;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        }

        .tit2 {
          color: #6d7175;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }

      .button_download {
        border-radius: 8px;
        border: 1px solid #dedede;
        color: #333;
        font-size: 14px;
        height: 100%;
        font-weight: 500;
        line-height: 20px;
        padding: 8px 16px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .line {
    height: 1px;
    background-color: #dedede;
    width: 100%;
    margin: 24px 0;
  }

  .upload_base {
    .upload_date {
      padding: 24px 16px;
      border-radius: 8px;
      display: flex;
      margin-top: 16px;
      flex-direction: column;
      align-items: center;
      border: 1px dashed #09b189;
      justify-content: space-between;
      background: rgba(9, 177, 137, 0.05);

      .message {
        color: #6d7175;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin: 16px 0;
      }

      .button_upload {
        border-radius: 8px;
        border: 1px solid #dedede;
        color: #333;
        font-size: 14px;
        height: 100%;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 16px;
        text-align: center;
        cursor: pointer;
      }

      .button_upload_rad {
        border-radius: 8px;
        border: 1px solid #d82c0d;
        color: #d82c0d;
        font-size: 14px;
        height: 100%;
        font-weight: 400;
        line-height: 20px;
        padding: 8px 16px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .dailog-model {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 48px;
      height: 48px;
    }
  }
}
</style>
