import tabsPsd from "../el_tabs_password";
import Storage from "@/commons/utils/storage.js";
export default {
  components: {
    tabsPsd,
  },
  data() {
    return {
      activeName: "first",
      schoolList: [],
      schoolId: "",
      item_sty: "",
      backSty: "",
      backStyleType: false,
    };
  },
  created() {
    Storage.remove("menu");
    Storage.remove("isMenu");
    Storage.remove("user");
  },
  methods: {
    mouseOver(item) {
      item.item_sty =
        " border-radius: 12px; border: 1px solid #09B189; background: rgba(9, 177, 137, 0.05);";
    },
    mouseLeave(item) {
      item.item_sty =
        " border-radius: 12px; background: #F7F8FA;  border: 1px solid #F7F8FA;";
    },
    backMouseOver() {
      this.backStyleType = true;
      this.backSty = " color:#09B189;";
    },
    backMouseLeave() {
      this.backStyleType = false;
      this.backSty = " color:#6D7175;";
    },
    onSelectSc(val) {
      this.schoolList = JSON.parse(val);
    },
    onLogin() {
      localStorage.setItem("schoolId", this.schoolId);
      this.pdateUserSchoolId();
      // this.$store.state.tabsPage = [
      //   {
      //     title: "首页",
      //     name: "/base/main/desk/desk",
      //   },
      // ];
      // this.$store.state.TabsValue = "/base/main/desk/desk";
      // this.$store.state.noticeShow = true;
      this.$router.push({
        path: "/base/main/desk/desk",
      });
    },
    goBack() {
      this.backStyleType = false;
      this.backSty = " color:#6D7175;";
      this.schoolList = [];
    },

    onClickSelectSc(row) {
      this.schoolId = row.schoolId;
      localStorage.setItem("schoolId", this.schoolId);
      this.pdateUserSchoolId();
      // this.$store.state.tabsPage = [
      //   {
      //     title: "首页",
      //     name: "/base/main/desk/desk",
      //   },
      // ];
      // this.$store.state.TabsValue = "/base/main/desk/desk";
      // this.$store.state.noticeShow = true;
      this.$router.push({
        path: "/base/main/desk/desk",
      });
    },

    //切换学校
    pdateUserSchoolId() {
      this.$axios({
        method: "get",
        url: "/system/user/updateUserSchoolId?schoolId=" + this.schoolId,
      }).then((res) => {
        if (res.code == 200) {
        } else {
          if (res.code == 37018) {
            this.$router.push({
              path: "/applyError",
              query: {
                id: this.schoolId,
              },
            });
          } else this.$message.error(res.describe);
        }
      });
    },
  },
};
