<!--
 * @Author: Lijiahui
 * @Date: 2020-10-19 22:04:38
 * @LastEditors: yuan wei lin
 * @LastEditTime: 2023-05-11 17:32:55
-->
<template>
  <div class="passwor_username_box">
    <el-form
      :model="ruleForm"
      style="width: 100%"
      :rules="rules"
      ref="login_form"
      class="demo-ruleForm"
    >
      <el-form-item prop="name" rules="name">
        <el-input
          placeholder="账号"
          prefix-icon="Avatar"
          v-model="ruleForm.name"
        >
        </el-input>
      </el-form-item>
      <el-form-item prop="password" rules="password">
        <el-input
          :type="typepsd"
          placeholder="密码"
          prefix-icon="Lock"
          v-model="ruleForm.password"
        >
        </el-input>
        <img @click="handleclose" class="typepsd_img" :src="imgsrc" alt="" />
      </el-form-item>
      <div class="forgetPass" style="height: 32px">
        <el-checkbox v-model="forgetChecked">记住密码</el-checkbox>
      </div>
      <el-button class="tabsPsd_btn" type="primary" @click="submitForm"
        >登录</el-button
      >
    </el-form>
  </div>

  <Verify
    mode="pop"
    :captchaType="'blockPuzzle'"
    :imgSize="{ width: '400px', height: '200px' }"
    ref="verify"
    @success="success"
  ></Verify>
</template>

<script>
import md5 from "md5";
import user from "@/api/user.js";
import Verify from "@/components/verifition/Verify";
export default {
  components: {
    Verify,
  },
  data() {
    return {
      forgetChecked: false,
      typepsd: "password",
      flag: true,
      isLoginClick: true,
      imgsrc: require("@/assets/images/denglu_ic_biyan.png"),
      ruleForm: {
        name: "",
        password: "",
        // number: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    success(data) {
      let param = {
        userCode: this.ruleForm.name,
        password: this.ruleForm.password,
        isNeedValidate: 1,
        captchaVerification: data.captchaVerification,
        // imgCode: ""
      };
      this.isLoginClick = false;
      user.getTimestamp().then((res) => {
        this.isLoginClick = true;
        param.date = res[2];
        param.password = md5(
          md5(param.password).toUpperCase() + param.date
        ).toUpperCase();
        this.$axios({
          method: "post",
          url: "/system/login/loginWebCaptcha",
          data: param,
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("schoolId", res.data.schoolId);
            localStorage.setItem("userId", res.data.userId);
            if (this.forgetChecked) {
              this.getStorage(this.ruleForm.name, this.ruleForm.password, 7);
            } else {
              this.clearStorage();
            }
            if (res.data.userDingtalkList.length == 1) {
              this.$store.state.tabsPage = [
                {
                  title: "首页",
                  name: "/base/main/desk/desk",
                },
              ];
              this.$store.state.TabsValue = "/base/main/desk/desk";
              this.$store.state.noticeShow = true;
              this.$router.push({
                path: "/base/main/desk/desk",
              });
            } else if (res.data.userDingtalkList.length > 1) {
              let string = JSON.stringify(res.data.userDingtalkList);
              this.$emit("selectsc", string);
            } else if (res.data.userDingtalkList.length == 0) {
              this.$message.error("暂无使用权限，请联系管理员");
            }
          } else this.$message.error(res.describe);
        });
        return;
        user
          .userLogin(param)
          .then((res) => {
            localStorage.setItem(
              "schoolId",
              res[2].schoolId == "null" ? "" : res[2].schoolId
            );
            if (this.forgetChecked) {
              this.setStorage(this.ruleForm.name, this.ruleForm.password, 7);
            } else {
              this.clearStorage();
            }
            if (res[0] == 200) {
              if (res[2].userDingtalkList.length == 1) {
                this.$store.state.tabsPage = [
                  {
                    title: "首页",
                    name: "/base/main/desk/desk",
                  },
                ];
                this.$store.state.TabsValue = "/base/main/desk/desk";
                this.$store.state.noticeShow = true;
                this.$router.push({
                  path: "/base/main/desk/desk",
                });
              } else if (res[2].userDingtalkList.length > 1) {
                let string = JSON.stringify(res[2].userDingtalkList);
                this.$emit("selectsc", string);
              } else {
              }
            } else {
              this.$message.warning(res[1]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    buildingParams() {
      let params = {
        userCode: this.ruleForm.name,
        password: this.ruleForm.password,
        isNeedValidate: 1,
      };
      return params;
    },

    handleclose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.typepsd = "password";
        this.imgsrc = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd = "text";
        this.imgsrc = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },

    handleForget() {
      this.$router.push("/base/main/forgetPsd/forgetPsd");
    },

    //  确认登录
    submitForm() {
      this.$refs.login_form.validate((valid, fields) => {
        if (valid && this.isLoginClick) {
          this.$refs.verify.show();
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    //设置Storage
    setStorage(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      localStorage.setItem("name", c_name);
      localStorage.setItem("password", c_pwd);
    },

    //读取Storage
    getStorage() {
      if (localStorage.getItem("name")) {
        this.ruleForm.name = localStorage.getItem("name");
        this.ruleForm.password = localStorage.getItem("password");
        this.forgetChecked = true;
      }
    },

    //清除Storage
    clearStorage() {
      localStorage.removeItem("name");
      localStorage.removeItem("password");
    },
  },

  created() {
    document.onkeydown = (e) => {
      let key = window.event.keyCode;
      if (key && key == 13) this.submitForm();
    };
    this.getStorage();
  },
};
</script>
<style lang="less" scoped>
@import "./css/elTabsPassword.less";
</style>
