<!--
 * @Author: 
 * @Date: 2024-04-29 16:24:17
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-06-26 17:56:53
 * @Description: 
-->
<template>
  <button :type="type" :disabled="disabled" class="hd-button">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "HdButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.hd-button {
  min-width: 88px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 36px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
  transition: all 0.25s;
  color: #333;
  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  &:hover {
    background: #f6f6f7;
  }
  &:active {
    background: #f1f2f3;
  }
  &:disabled {
    color: #8c9196;
    cursor: not-allowed;
    background: #fff;
  }

  // 绿色创建
  &[type="primary"] {
    background: #09b189;
    color: #fff;
    border: none;
    &:hover {
      background: #13bd94;
    }
    &:active {
      background: #089976;
    }
    &:disabled {
      color: #8c9196;
      cursor: not-allowed;
      background: #f1f1f1;
    }
  }

  // 红色
  &[type="danger"] {
    // background: #d63c40;
    color: #d72c0d;
    border: 1px solid #d82c0d;
    &:hover {
      background: #fff0f0;
    }
    &:active {
      background: #ffe9e8;
    }
    &:disabled {
      color: #8c9196;
      cursor: not-allowed;
      background: #f1f1f1;
    }
  }
}
</style>
