<template>
    <div class="selectfunction_base">
      <!-- 左边 -->
      <div class="left_base" v-show="!isModelCombination">
        <div v-for="item in funcData" :key="item.id" class="left_item_div"
          :class="item.funId == selectChildrenId ? 'check_true' : ''" @click="selectBaseFunction(item)">
          <div style="display: flex; align-items: center">
            <el-checkbox v-model="item.selectType" :true-label="2" :false-label="0" :disabled="isDisabledAll == 3"
              :indeterminate="item.selectType == 1" @change="checkAllBaseFunction(item)"></el-checkbox>
            <el-text class="checkbox_span" truncated>{{ item.funName }}</el-text>
          </div>
  
          <img v-show="item.children.length != 0" src="../../assets/img/right_jt_icon.png" />
        </div>
      </div>
      <div class="line" v-show="!isModelCombination"></div>
      <!-- 右边 -->
      <div class="right_base" :style="{ border: !isModelCombination ? 'none' : '' }">
        <p v-show="!isModelCombination" style="margin: 10px 0;">选择权限范围</p>
        <div v-for="item in selectChildren.children" :key="item.funId" class="right_item_base">
          <div class="children_item" @click="clickChildrenItem(item)">
            <div class="checkbox_div">
              <el-checkbox v-model="item.selectType" :true-label="2" :false-label="0" class="checkbox_div"
                :indeterminate="item.selectType == 1" :disabled="isDisabled "
                @change="checkSecondFunction(item, selectChildren)"></el-checkbox>
              <span class="checkbox_span">{{ item.funName }}</span>
            </div>
  
            <div v-if="item.children.length != 0">
              <img v-if="item.childrenShow" src="../../assets/img/bottom_jt_icon.png" />
              <img v-if="!item.childrenShow" src="../../assets/img/top_jt_icon.png" />
            </div>
          </div>
          <div class="line1" v-if="item.children.length != 0 && !item.childrenShow"></div>
          <div v-for="(ite, index) in item.children" :key="ite.funId" v-if="item.children && !item.childrenShow">
            <div class="children_item margin_left16" @click="clickChildrenItem(ite)">
              <div class="checkbox_div">
                <el-checkbox v-model="ite.selectType" :true-label="2" :false-label="0" class="checkbox_div"
                  :disabled="isDisabled " :indeterminate="ite.selectType == 1"
                  @change="checkThirdFunction(ite, item, selectChildren)"></el-checkbox>
                <span class="checkbox_span">{{ ite.funName }}</span>
              </div>
  
              <div v-if="ite.children.length != 0">
                <img v-if="ite.childrenShow" src="../../assets/img/bottom_jt_icon.png" />
                <img v-if="!ite.childrenShow" src="../../assets/img/top_jt_icon.png" />
              </div>
            </div>
            <div class="line1 margin_left16" v-if="index != item.children.length - 1 && ite.children.size != 0"></div>
            <div class="line1 margin_left16" v-if="index == item.children.length - 1 &&
        ite.children.size != 0 &&
        !ite.childrenShow
        "></div>
            <div class="checkbox_children_div">
              <div v-for="it in ite.children" :key="it.funId" v-if="ite.children && !ite.childrenShow"
                class="margin_left32">
                <div class="checkbox_div">
                  <el-checkbox v-model="it.selectType" :true-label="2" :false-label="0"
                    :disabled="isDisabled " class="checkbox_div"
                    @change="checkFourthFunction(it, ite, item)"></el-checkbox>
                  <span class="checkbox_span" style="margin: 8px">
                    {{ it.funName }}</span>
                </div>
              </div>
            </div>
  
            <div class="line1 margin_left32" v-if="index != item.children.length - 1 &&
        ite.children.length != 0 &&
        index != ite.children.length - 1 &&
        !ite.childrenShow
        "></div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: [
      "isDisabled",
      "isModelCombination",
      'funcData'
    ],
    data() {
      return {
        isDisabled: this.isDisabled, //是否左侧不可编辑
        funcData: this.funcData,
        selectChildren: {},
        selectChildrenId: "",
        isModelCombination: this.isModelCombination,
      };
    },
    created() {
      this.getRoleFuncsTree();
    },
    methods: {
      getRoleFuncsTree() {
        if (this.funcData.length != 0) {
          this.selectChildrenId = this.funcData[0].funId;
          this.selectChildren = this.funcData[0];
        }
      },
      //点击四级权限状态发生变化
      checkFourthFunction(it, ite, item) {
        console.log(it);
        let selectTypeCount = {
          0: 0,
          1: 0,
          2: 0,
        };
        ite.children.forEach((element) => {
          selectTypeCount[element.selectType]++;
        });
        if (selectTypeCount[0] === ite.children.length) {
          ite.selectType = 0;
        } else if (selectTypeCount[2] === ite.children.length) {
          ite.selectType = 2;
        } else {
          ite.selectType = 1;
        }
        let selectTypeCount2 = {
          0: 0,
          1: 0,
          2: 0,
        };
        item.children.forEach((element) => {
          selectTypeCount2[element.selectType]++;
        });
        if (selectTypeCount2[0] === item.children.length) {
          item.selectType = 0;
        } else if (selectTypeCount2[2] === item.children.length) {
          item.selectType = 2;
        } else {
          item.selectType = 1;
        }
        this.setCheckBaseType();
        // this.checkSecondFunction(item)
      },
      //点击三级权限状态发生变化
      checkThirdFunction(ite, item) {
        if (ite.children.length != 0) {
          ite.children.forEach((element) => {
            element.selectType = ite.selectType;
          });
        }
        let selectTypeCount = {
          0: 0,
          1: 0,
          2: 0,
        };
        item.children.forEach((element) => {
          selectTypeCount[element.selectType]++;
        });
        if (selectTypeCount[0] === item.children.length) {
          item.selectType = 0;
        } else if (selectTypeCount[2] === item.children.length) {
          item.selectType = 2;
        } else {
          item.selectType = 1;
        }
        this.setCheckBaseType();
      },
      //设置一级权限状态
      setCheckBaseType() {
        let selectTypeCount = {
          0: 0,
          1: 0,
          2: 0,
        };
        this.selectChildren.children.forEach((element) => {
          selectTypeCount[element.selectType]++;
        });
        if (selectTypeCount[0] === this.selectChildren.children.length) {
          this.selectChildren.selectType = 0;
        } else if (selectTypeCount[2] === this.selectChildren.children.length) {
          this.selectChildren.selectType = 2;
        } else {
          this.selectChildren.selectType = 1;
        }
      },
      //点击二级权限状态发生变化
      checkSecondFunction(item) {
        if (item.children.length != 0) {
          item.children.forEach((element) => {
            element.selectType = item.selectType;
            if (item.children.length != 0) {
              if (element.children.length != 0) {
                element.children.forEach((ele) => {
                  ele.selectType = item.selectType;
                });
              }
            }
          });
        }
        this.setCheckBaseType();
      }, //点击右侧base展开、关闭
      clickChildrenItem(item) {
        item.childrenShow = !item.childrenShow;
      },
      //点击选择功能权限父级
      selectBaseFunction(item) {
        this.selectChildrenId = item.funId;
        if (item.children.size != 0) {
          item.children.forEach((element, index) => {
            if (index == 0) {
              element.childrenShow = false;
            } else {
              element.childrenShow = true;
            }
            if (element.children.size != 0) {
              element.children.forEach((ele, ind) => {
                if (ind == 0) {
                  ele.childrenShow = false;
                } else {
                  ele.childrenShow = true;
                }
              });
            }
          });
        }
        this.selectChildren = item;
      },
      //功能权限父级状态发生变化
      checkAllBaseFunction(val) {
        console.log(val.selectType);
        val.children.forEach((ite) => {
          ite.selectType = val.selectType;
          if (ite.children.size != 0) {
            ite.children.forEach((element) => {
              element.selectType = ite.selectType;
              if (element.children.size != 0) {
                element.children.forEach((ele) => {
                  ele.selectType = ite.selectType;
                });
              }
            });
          }
        });
      },
      getData() {
        console.log(this.funcData);
        return this.funcData;
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .selectfunction_base {
    border-radius: 8px;
    /* width: 100%; */
    border: 1px solid #dedede;
    background: #fff;
    padding: 16px;
    margin-bottom: 32px;
    display: flex;
    flex: 1;
  
    .left_base {
      .left_item_div {
        display: flex;
        padding: 10px 12px;
        align-items: center;
        height: 30px;
        justify-content: space-between;
  
        .checkbox_span {
          color: #333;
          font-size: 14px;
          font-weight: 500;
          width: 80px;
          margin: 0 8px;
        }
      }
  
      .check_true {
        border-radius: 8px;
        background: #edeeef;
      }
  
      img {
        width: 20px;
        height: 20px;
      }
    }
  
    .right_base {
      width: 100%;
  
      span {
        color: #8c9196;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: 10px 0;
        /* 142.857% */
      }
  
      .right_item_base {
        border-radius: 8px;
        border: 1px solid #dedede;
        padding: 16px;
        margin-bottom: 16px;
  
        .checkbox_div {
          display: flex;
          align-items: center;
        }
  
        .checkbox_children_div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
  
        .checkbox_span {
          color: #333;
          font-size: 14px;
          font-weight: 400;
          margin: 0 8px;
        }
  
        .children_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
  
          img {
            width: 20px;
            height: 20px;
          }
        }
  
        .margin_left16 {
          margin-left: 16px;
        }
  
        .margin_left32 {
          margin-left: 32px;
        }
      }
    }
  
    .line {
      background: #dedede;
      width: 1px;
      margin: 0 24px;
    }
  
    .line1 {
      background: #dedede;
      height: 1px;
      margin: 16px 0;
    }
  }
  </style>
  