/*
 * @Author: yuan wei lin
 * @Date: 2023-05-09 18:38:54
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2023-08-14 17:31:48
 * @Descripttion:
 */

let copyText = (text) => {
  // 动态创建 textarea 标签
  let textarea = document.createElement("textarea");
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = "readonly";
  textarea.style.position = "absolute";
  textarea.style.left = "-9999px";
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  textarea.value = text;
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea);
  // 选中值并复制
  textarea.select();
  let result = document.execCommand("Copy");
  document.body.removeChild(textarea);
  return result;
};

// 文件上传
let UploadInputFile = (options) => {
  let input = document.createElement("input");
  input.type = "file";
  input.accept = ".xls,.xlsx";
  input.click();
  input.addEventListener("change", (e) => {
    if (typeof options.callback === "function") {
      // options.callback(e.path[0].files[0]); // vue2
      options.callback(e.target.files[0]); // vue3
    }
  });
};

import { ElMessage } from "element-plus";
let messageText = (res) => {
  let text = "";
  switch (res.code) {
    case 3000:
      ElMessage({
        message: res.describe,
        type: "error",
      });
      break;

    case 500:
      ElMessage({
        message: res.result,
        type: "error",
      });
      break;

    case 5002:
      ElMessage({
        message: res.describe,
        type: "error",
      });
      break;
    case 5004:
      ElMessage({
        message: res.describe,
        type: "error",
      });
      break;
    // case 18001:
    //   ElMessage({
    //     message: res.describe,
    //     type: "error",
    //   });
    //   break;
    default:
      if (res.rsult) {
        if (JSON.stringify(res.result) == "object") {
          text = res.describe;
        } else text = res.result;
        ElMessage({
          message: res.result,
          type: "error",
        });
      } else {
        ElMessage({
          message: res.describe,
          type: "error",
        });
      }

      break;
  }
};

import axios from "axios";
//通过文件下载url拿到对应的blob对象
let downloadFile = (name, url) => {
  axios
    .get("/downloadFile" + url, {
      responseType: "blob",
    })
    .then((blob) => {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let type = url.split(".")[1];
      link.download = `${name}.${type}`;
      link.click();
      document.body.appendChild(link);
      document.body.removeChild(link);
    })
    .catch(() => {
      // this.uploadClearStudent = false;
      // this.$refs.newStudentUpload.submit();
    });
};

//data:文件流,通过文件流直接下载,fileName:需要加文件后缀名
let filedownloadFile = (data, fileName) => {
  let blob = new Blob([data]);
  let url = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.click();
  URL.revokeObjectURL(url); // 释放内存
};
//data:文件流,通过文件流直接下载,fileName:需要加文件后缀名
let filedownloadZipFile = (data, fileName) => {
  let blob = new Blob([data], { type: 'application/zip' });
  const url = window.URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url); // 释放内存
};

// 数组去重
let uniqueFunc = (arr, uniId) => {
  const res = new Map();
  return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1));
};

export default {
  UploadInputFile,
  messageText,
  downloadFile,
  copyText,
  uniqueFunc,
  filedownloadFile,
  filedownloadZipFile,
};
