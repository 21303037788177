export default {
  // props:[
  //   'title',
  //   'width',
  //   'type',
  //   'url',
  //   'param',
  //   'treeid',
  //   'treeProps',
  //   'checkedId',
  //   'dialogTreeIsShow',
  //   'checkbox',
  //   'treeType',
  //   'isLazy',
  //   'lazyLoadParam',
  //   'isCheckedNodeLeaf',
  //   'isExpandedFirst',
  //   'checkStrictly',
  //   'treeDataFilter',
  // ],
  props: {
    /**
     * 控件dialog标题名称
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * 控件宽度
     */
    width: {
      type: String,
      default: "45%",
    },
    /**
     *  左侧数据类型是否为树结构
     *  istree 树结构（需要便利结果集封装children数据）
     *  isselect 单次选择数据
     *  默认值：istree
     */

    type: {
      type: String,
      default: "istree",
    },
    /**
     *  树请求接口路径
     */
    url: {
      type: String,
      default: "/system/dept/getTreeall",
    },
    /**
     *  树请求接口路径查询参数
     */
    param: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     *  树ID
     */
    treeid: {
      type: String,
      default: "tree",
    },
    /**
     *  树默认父子结构等配置
     *  id 节点数据主键key名
     *  children 子节点数据key名
     *  lable 节点数据展示名称key名
     *  pid 递归查询时比对数据父节点key名
     *  rootParentId 根节点值
     */
    treeProps: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     *  默认选中节点id 多个以逗号分隔
     *  例如：12121,6546656,455
     */
    checkedId: {
      type: String,
      default: "",
    },
    deptId: {
      type: String,
      default: "",
    },
    /**
     *  控制树控件显示值
     *  true显示 false 关闭
     */
    dialogTreeIsShow: {
      type: Boolean,
      default:false,
    },
    /**
     *  控件选择是否多选
     *  true多选 false单选 默认多选
     */
    checkbox: {
      type: Boolean,
      default: true,
    },
    /**
     * 树类型
     * 预置值为：
     * dept 部门树
     * role 角色树
     * func 权限树
     * user 人员树
     */
    treeType: {
      type: String,
      default: "",
    },
    /**
     * 是否为异步树
     *  默认 否
     */
    isLazy: {
      type: Boolean,
      default: false,
    },
    /**
     * 异步查询处理每次查询条件的方法
     * 入参为当前节点数据
     */
    lazyLoadParam: {
      type: Function,
    },
    /**
     * 是否打开第一层节点
     * 默认是
     * 仅限异步树
     */
    isExpandedFirst: {
      type: Boolean,
      default: true,
    },
    /**
     * 是否仅根节点可选择
     * 默认否
     */
    isCheckedNodeLeaf: {
      type: Boolean,
      default: false,
    },
    checkStrictly: {
      type: Boolean,
      default: true,
    },
    treeDataFilter: {
      type: Function,
      default: (data) => {
        return data;
      },
    },
  },
  data() {
    return {
      showTags: false,
      tags: [],
      tagsId: [],
      communityid: [],
      personname: [],
      personphone: [],
      props: {
        id: "id",
        children: "children",
        label: "name",
        pid: "pId",
        rootParentId: "0",
        isLeaf: "leaf",
        ischecked: "ischecked",
        communityid: "communityid",
        personname: "personname",
        personphone: "personphone",
      },
      treeLoading: false,
      checkeds: [],
      data: [],
      filterText: "",
      defaultUrl: {
        dept: "/system/dept/getTreeall",
        role: "/system/role/getTreeall",
        func: "/system/func/getTreeall",
        user: "/system/user/getUserDeptTreeall",
      },
      checkedTree: [],
      checkAll: false,
    };
  },
  emits: ["closeTree"],
  watch: {
    dialogTreeIsShow(val) {
      if (val == false) {
        this.checkeds = [];
        this.data =[]
        this.filterText = ""
      }
    },
    filterText(val) {
      this.$refs[this.treeid].filter(val);
    },
    checkeds(vals) {
      var nodes = this.$refs[this.treeid].getCheckedNodes();
      if (nodes.length == 0 && vals.length > 0) {
        return;
      }
      nodes = nodes.concat(this.$refs[this.treeid].getHalfCheckedNodes());
      var this_ = this;
      var len = vals.length;
      const List = (len) => [...new Array(len).keys()];
      this_.tags = List(len);
      this_.tagsId = List(len);
      this_.communityid = List(len);
      this_.personname = List(len);
      this_.personphone = List(len);
      var tdata = List(len);
      nodes.forEach(function (item) {
        vals.forEach(function (id, i) {
          if (item[this_.props.id] == id) {
            if (item[this_.props.ischecked] != false) {
              this_.tags[i] = item[this_.props.label];
              this_.tagsId[i] = id;
              this_.communityid[i] = item[this_.props.communityid];
              this_.personname[i] = item[this_.props.personname];
              this_.personphone[i] = item[this_.props.personphone];
              tdata[i] = item[this_.props.label];
            }
          }
        });
      });
      var reduce = 0;
      tdata.forEach(function (item, i) {
        if (typeof item == "number") {
          this_.tags.splice(i - reduce, 1);
          this_.tagsId.splice(i - reduce, 1);
          this_.communityid.splice(i - reduce, 1);
          this_.personname.splice(i - reduce, 1);
          this_.personphone.splice(i - reduce, 1);
          reduce++;
        }
      });
      if (this_.tagsId.length < this_.data.length) {
        this_.checkAll = false;
      } else if (this_.tagsId.length == this_.data.length) {
        this_.checkAll = true;
      }
    },
  },
  methods: {
    handleClose(index) {
      var this_ = this;
      this_.tags.splice(index, 1);
      this_.tagsId.splice(index, 1);
      this_.communityid.splice(index, 1);
      this_.personname.splice(index, 1);
      this_.personphone.splice(index, 1);
      this.$refs[this.treeid].setCheckedKeys(this.tagsId);
      if (this_.tagsId.length < this_.data.length) {
        this_.checkAll = false;
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data[this.props.label].indexOf(value) !== -1;
    },
    handleCheckChange(data, check) {
      if (!this.checkbox) {
        this.checkeds = [data[this.props.id]];
        this.$refs[this.treeid].setCheckedNodes([]);
        this.$refs[this.treeid].setCheckedNodes([data]);
      } else {
        this.checkeds = check.checkedKeys;
      }
      this.$emit("changeTreeCheck", {
        dept_comm: data.id,
      });
    },
    treeFormat(data) {
      var this_ = this;
      // console.log(data);
      data.forEach(function (child) {
        if (child.isparent == "true") {
          child.leaf = false;
          if (this_.isCheckedNodeLeaf) {
            child.disabled = true;
          }
        } else {
          child.leaf = true;
        }
        if (typeof child.disabled == "string") {
          if (child.disabled == "true") {
            child.disabled = true;
          } else {
            child.disabled = false;
          }
        }
      });
      return data;
    },
    loadTree(node, resolve) {
      var url = this.url;
      var this_ = this;
      var param = this.param;
      if (this.lazyLoadParam) {
        param = this.lazyLoadParam(node);
      }
      if (node.level == 0) {
        this.data = [];
      }
      this.http
        .ajaxHandler()
        .success(function (code, msg, data) {
          // console.log(data);
          if (code == 200) {
            if (this_.treeDataFilter) {
              data = this_.treeDataFilter(data);
            }
            let tree = this_.treeFormat(data);
            if (node.level == 0) {
              this_.data = tree;
              if (this_.isExpandedFirst && !tree[0][this_.props.isLeaf]) {
                this_.checkedTree.push(tree[0][this_.props.id]);
              }
            } else {
              resolve(tree);
              if (this_.isExpandedFirst && !tree[0][this_.props.isLeaf]) {
                this_.checkedTree.push(tree[0][this_.props.id]);
              } else {
                this_.init();
              }
            }
          } else {
            this_.$message({
              message: msg,
              type: "warning",
            });
          }
          this_.treeLoading = false;
        })
        .post({
          url: url,
          data: param,
        });
    },
    initTree() {
      // this.treeLoading = true;
      if (this.isLazy) {
        this.loadTree({
          level: 0,
        });
      } else {
        this.initTreeQuery();
      }
    },
    openTree(item) {
      if (item[this.props.children] && item[this.props.children].length > 0) {
        this.checkedTree.push(item.id);
        this.openTree(item[this.props.children][0]);
      }
    },
    initTreeQuery() {
      var url = this.url;
      if (this.treeType) {
        url = this.defaultUrl[this.treeType];
      }
      var this_ = this;
      this.http
        .ajaxHandler()
        .success(function (code, msg, data) {
          this_.treeLoading = false;
          if (code == 200) {
            if (this_.treeType == "user") {
              this_.props.label = "mobileLastFour";
            }
            if (this_.treeDataFilter) {
              data = this_.treeDataFilter(data);
            }
            if (this_.type != "istree") {
              this_.data = data;
            } else {
              // console.log(this_.props.rootParentId);
              this_.data = this_.formatTreeData(data, this_.props.rootParentId);
            }
            if (this_.isExpandedFirst) {
              this_.openTree(data[0]);
            }
            this_.init();
          } else {
            this_.$message({
              message: msg,
              type: "warning",
            });
          }
        })
        .post({
          url: url,
          data: this.param,
        });
    },
    formatTreeData(data, pId) {
      var this_ = this;
      var child = [];
      var remove = [];
      var flag = false;
      if (pId) {
        var c = this_.formatData(data, pId);
        if (c && c.length == 0) {
          flag = true;
        } else {
          return c;
        }
      } else {
        flag = true;
      }
      if (flag) {
        data.forEach(function (item, i) {
          data.forEach(function (item2) {
            if (item[this_.props.pid] == item2[this_.props.id]) {
              remove.push(i);
            }
          });
        });
      }
      data.forEach(function (item, i) {
        if (!remove.includes(i)) {
          var c = this_.formatData(data, item[this_.props.id]);
          item[this_.props.children] = c;
          child.push(item);
        }
      });
      return child;
    },
    formatData(data, pId) {
      var this_ = this;
      var child = [];
      data.forEach(function (item) {
        if (item[this_.props.pid] == pId) {
          item[this_.props.children] = this_.formatData(
            data,
            item[this_.props.id]
          );
          if (item.hasOwnProperty("disabled")) {
            // console.log(111);
            if (typeof item.disabled == "string") {
              if (item.disabled == "true") {
                item.disabled = true;
              } else {
                item.disabled = false;
              }
            }
          } else {
            if (this_.isCheckedNodeLeaf && item.children.length > 0) {
              child.disabled = true;
            }
          }
          child.push(item);
        }
      });
      return child;
    },
    init() {
      if (this.checkedId && this.checkedId.length > 0) {
        this.checkeds = this.checkedId.split(",");
      } else {
        this.checkeds = [];
      }
      this.$nextTick().then(() => {
        this.checkeds = this.$refs[this.treeid].getCheckedKeys();
      });
      if (
        this.treeType &&
        this.treeType == "dept" &&
        this.checkedId &&
        this.data.length != 0
      ) {
        this.data.forEach((element) => {
          this.setDeptCheckedId(element);
        });
      }
      // console.log(this.data);
    },
    setDeptCheckedId(element) {
      if (this.deptId == element.id) {
        if (element.children && element.children.size != 0) {
          element.disabled = true;
          element.children.forEach((ele) => {
            this.setDeptDis(ele);
          });
        }
      } else {
        if (element.children && element.children.length != 0) {
          element.children.forEach((ele) => {
            this.setDeptCheckedId(ele);
          });
        }
      }
    },
    setDeptDis(element) {
      element.disabled = true;
      if (element.children && element.children.size != 0) {
        element.children.forEach((ele) => {
          this.setDeptDis(ele);
        });
      }
    },
    closeDialogTree() {
      this.$emit("closeTree");
    },
    sureDialogTree() {
      this.$emit("saveTreeCheck", {
        id: this.tagsId.join(","),
        name: this.tags.join(","),
        communityid: this.communityid.join(","),
        personname: this.personname.join(","),
        personphone: this.personphone.join(","),
      });
    },
    checkedAll(val) {
      if (val) {
        this.$refs[this.treeid].setCheckedNodes(this.data);
        this.$nextTick().then(() => {
          this.checkeds = this.$refs[this.treeid].getCheckedKeys();
        });
      } else {
        this.$refs[this.treeid].setCheckedKeys([]);
        this.checkeds = [];
      }
    },
  },
  created: function () {
    this.props = Object.assign(this.props, this.treeProps);
  },
};
