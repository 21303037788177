<template>
  <el-container style="height: 100%">
    <el-main style="padding: 0">
      <el-table
        :ref="tblRef"
        :data="tableData"
        style="width: 100%"
        height="100%"
        fit
        @row-dblclick="rowDblclick"
        @row-click="clickRow"
        v-loading="loading"
        :cell-style="cellStyle"
        :border="border"
        @selection-change="selectionChange"
        highlight-current-row
        @current-change="currentChange"
      >
        <el-table-column
          v-if="isshow"
          type="selection"
          align="center"
          fixed
        ></el-table-column>
        <template v-for="(column, index) in columns">
          <el-table-column
            :prop="column.prop"
            :key="column.label"
            :label="column.label"
            :align="column.align"
            :width="column.width"
            v-if="column.colmunShow != false"
          >
            <template #default="scope">
              <template v-if="!column.render">
                <template v-if="column.formatter">
                  <span v-html="column.formatter(scope.row, column)"></span>
                </template>
                <template v-else>
                  <span>{{ scope.row[column.prop] }}</span>
                </template>
              </template>
              <div class="demo-image__error" v-else>
                <el-image :src="scope.row.adPic"> </el-image>
              </div>
            </template>
          </el-table-column>
        </template>
        <el-table-column
          label="操作"
          align="center"
          v-if="showOpe"
          :width="operateWidth"
        >
          <template #default="scope">
            <el-button
              type="text"
              v-for="(btn, index) in operates"
              :type="btn.type"
              :key="index"
              :icon="btn.icon"
              :disabled="btn.disabled"
              :data-url="btn.url ? btn.url : ''"
              @click.native.prevent="btn.method(index, scope.row, btn.url)"
              >{{
                btn.label
                  ? btn.label
                  : btn.lablemethod(index, scope.row, btn.url)
              }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-main>
    <!-- :current-page="currentPage" -->
    <el-footer height="40">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="param.page"
        :page-sizes="pageSizes"
        :page-size="param.size"
        :layout="layout"
        :total="total"
        v-show="pagination"
      >
      </el-pagination>
    </el-footer>
  </el-container>
</template>

<script>
import commonTable from "./js/commonTable";
export default {
  ...commonTable,
};
</script>
<style lang="less" scoped>
.el-button--mini {
  border: none;
  color: rgb(24, 84, 255);
  background-color: transparent;
}
.el-button {
  padding: 8px 5px;
  background-color: transparent;
}
/*  // :deep(.el-table>.el-table__body-wrapper){
  //   height: auto !important;
  // } */
/* :deep(.el-table__body tr.current-row>td) {
        background-color: #eef0f4 !important;
    } */
:deep(.demo-image__error .image-slot) {
  font-size: 30px;
}

:deep(.demo-image__error .image-slot .el-icon) {
  font-size: 30px;
}

:deep(.demo-image__error .el-image) {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

:deep(.el-image-viewer__wrapper) {
  position: absolute;
}

:deep(.el-dialog) {
  width: 50%;
  height: 300px;
}

:deep(.el-dialog__body img) {
  width: 100%;
  padding: 0;
}
</style>
