/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 11:04:12
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-04-26 14:47:35
 */
/**
 * @name: Storage  默认存储session，true为 local
 * @Date: 2020-05-11
 * @return: 本地存储工具
 */
// import Vue from 'vue';
const KEY_PREFIX = "vito.";

export default {
  KEYS: {
    TOKEN: `${KEY_PREFIX}token`,
  },

  get: function (key, isSession) {
    if (!this.isLocalStorage()) {
      return undefined;
    }
    let value = this.getStorage(isSession).getItem(key);
    if (value) return JSON.parse(value);
    return undefined;
  },

  set: function (key, value, isSession) {
    if (!this.isLocalStorage()) {
      return undefined;
    }
    value = JSON.stringify(value);
    this.getStorage(isSession).setItem(key, value);
  },

  remove: function (key, isSession) {
    if (!this.isLocalStorage()) return undefined;
    this.getStorage(isSession).removeItem(key);
  },

  removeAll: function (keys = []) {
    Array.from(keys.concat(["TOKEN"])).forEach((item) =>
      this.remove(this.KEYS[item])
    );
  },

  getStorage: function (isSession) {
    return isSession ? localStorage : sessionStorage;
  },
  isLocalStorage: function () {
    try {
      if (!window.sessionStorage) {
        console.warn("不支持本地存储");
        return false;
      }
      return true;
    } catch (e) {
      console.warn("本地存储已关闭");
      return false;
    }
  },
  addRouter: function (data, val) {
    let has = false;
    let len = data.length;
    for (var i = 0; i < len; i++) {
      let tmp = data[i];
      if (tmp.name == val.name) {
        data[i] = val;
        has = true;
        break;
      }
    }
    if (!has) {
      data.push(val);
    }
  },
  formatTreeData(data, pId, USERROLE) {
    var this_ = this;
    var child = [];
    var jump = [];
    if (pId == "0") {
    }
    data.forEach(function (item) {
      if (
        item.pId == pId &&
        (item.funType == "sys" ||
          item.funType == "moudle" ||
          item.funType == "menu" ||
          item.funType == "jump")
      ) {
        var c = this_.formatTreeData(data, item.id);
        if (c.children && c.children.length > 0) {
          item.children = c.children;
        }
        if (c.jump && c.jump.length > 0) {
          item.jump = c.jump;
        }
        if (item.funType == "jump") {
          jump.push(item);
        } else {
          child.push(item);
        }
      }
      if (USERROLE) {
        USERROLE.add(item.link); //存储全局用户权限 拦截用户按钮 或者访问菜单
      }
    });
    child.sort(function (a, b) {
      return a.orderBy - b.orderBy;
    });
    return { children: child, jump: jump };
  },

  formatTreeDataMain(data, pId, USERROLE) {
    var this_ = this;
    var child = [];
    var jump = [];
    if (pId == "0") {
    }
    data.forEach(function (item) {
      if (item.pId == pId) {
        var c = this_.formatTreeDataMain(data, item.id);
        if (c.children && c.children.length > 0) {
          item.children = c.children;
        }
        if (c.jump && c.jump.length > 0) {
          item.jump = c.jump;
        }
        if (item.funType == "jump") {
          jump.push(item);
        } else {
          child.push(item);
        }
      }
      if (USERROLE) {
        USERROLE.add(item.link); //存储全局用户权限 拦截用户按钮 或者访问菜单
      }
    });
    child.sort(function (a, b) {
      return a.orderBy - b.orderBy;
    });
    return { children: child, jump: jump };
  },
  getDataRouter: function (data, res, fatherName) {
    let this_ = this;
    data.forEach((item) => {
      let tmp = {};
      let tabName = "";
      if (!fatherName) {
        tabName = item.name;
      } else tabName = fatherName + "/" + item.name;
      if (item.link == "#" || item.link == "" || item.link == undefined) {
        tmp = { path: item.link, tabName: tabName, meta: { title: item.name } };
      } else {
        let link = item.component ? item.component : item.link;
        tmp = {
          id: item.id,
          name: item.link,
          path: item.link,
          meta: {
            title: item.name,
            tabName: tabName,
            funCode: item.funCode,
          },
          component: () =>
            require.ensure([], (require) => require(`@/views${link}.vue`)),
        };
        this_.addRouter(res, tmp);
      }
      if (item.children) {
        this_.getDataRouter(item.children, res, tabName);
      }
      if (item.jump && item.jump.length > 0) {
        this_.getDataRouter(item.jump, res, tabName);
      }
    });
  },
};
