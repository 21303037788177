/*
 * @Author: Lijiahui
 * @Date: 2020-10-16 10:32:39
 * @LastEditors: Yuan Wei Lin
 * @LastEditTime: 2024-05-31 11:17:31
 */
import Storage from "@/commons/utils/storage.js";

const mainRouter = [
  {
    path: "/",
    component: () => import("../views/base/main/login/Login.vue"),
  },
  {
    path: "/index",
    // component: () => import("../views/base/main/home/Home.vue"),
    component: () => import("../views/home/index.vue"),
    children: [
      {
        path: "/",
        name: "desk",
        redirect: "/base/main/desk/desk",
      },
      {
        path: "/base/main/desk/desk",
        name: "desk",
        component: () => import("../views/base/main/desk/desk.vue"),
      }
    ],
  },
  {
    path: "/applyError",
    name: "applyError",
    component: () => import("../views/applyError/index.vue"),
  },
  {
    path: "/base/main/forgetPsd/forgetPsd",
    name: "forgetPsd",
    component: () => import("../views/base/main/forgetPsd/forgetPsd.vue"),
  },
  {
    path: "/designer",
    name: "designer",
    component: () => import("../components/formDesigner.vue"),
  },
  {
    path: "/campusDataScreen",
    name: "campusDataScreen",
    component: () => import("@/views/campusDataScreen/index.vue"),
  },
];

let menu = Storage.get("menu");
if (menu) {
  menu = Storage.formatTreeData(menu, "0").children;
  mainRouter.forEach((val, index) => {
    if (val.path == "/index") {
      let tmp = [];
      Storage.getDataRouter(menu, tmp);
      tmp = val.children.concat(tmp);
      val.tmp = val.children;
      val.children = tmp;
    }
  });
}
export default mainRouter;
