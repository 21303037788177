<template>
  <div>
    <el-dialog
      v-model="dialogTreeIsShow"
      :width="width"
      append-to-body
      @open="initTree"
      width="41.6%"
      :title="title"
      v-bind:show-close="false"
    >
      <template v-slot:title="scope" class="dialog-title">
        <span v-show="title" class="title-name">{{ title }}</span>
        <el-button
          icon="el-icon-close"
          @click="closeDialogTree"
          type="text"
          class="el-dialog__headerbtn icon-size"
          >X</el-button
        >
      </template>
      <el-container class="selDialog">
        <el-aside
          width="50%"
          style="
            box-shadow: none;
            padding: 10px 0px;
            position: relative;
            padding-top: 45px;
          "
        >
          <div
            style="
              position: absolute;
              top: 10px;
              width: 100%;
              background-color: #fff;
            "
          >
            <el-input
              placeholder="搜索"
              v-model="filterText"
              class="fatherSearch"
            >
            </el-input>
          </div>

          <el-tree
            style="height: 100%; overflow-y: auto"
            :data="data"
            @check="handleCheckChange"
            :filter-node-method="filterNode"
            check-on-click-node
            show-checkbox
            default-expand-all
            :node-key="props.id"
            v-bind:check-strictly="checkStrictly"
            :ref="treeid"
            :props="props"
            :default-checked-keys="checkeds"
            :load="loadTree"
            v-bind:lazy="isLazy"
            :default-expanded-keys="checkedTree"
            v-loading="treeLoading"
          >
          </el-tree>
        </el-aside>
        <el-main style="border-left: 1px solid #ddd; width: 50%">
          <p style="margin-top: 0">已选：</p>
          <el-tag
            class="selectTags"
            @close="handleClose(index)"
            closable
            v-for="(item, index) in tags"
            :key="index"
          >
            {{ item }}
          </el-tag>
        </el-main>
      </el-container>
      <!-- <el-checkbox
        v-model="checkAll"
        @change="checkedAll"
        style="margin-top: 10px; margin-left: 20px"
        v-if="!isLazy && checkbox"
        >全选</el-checkbox
      > -->
      <div style="width: 100%; text-align: right; margin-top: 20px">
        <el-button @click="closeDialogTree">取 消</el-button>
        <el-button type="primary" @click="sureDialogTree">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tree from "./js/tree";
export default {
  ...tree,
};
</script>

<style lang="less" scoped>
.title-name {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.icon-size {
  padding: 4px;
  :deep(.el-icon-close) {
    width: 16px;
    height: 16px;
    color: #909399;
  }
  :deep(.el-icon-close:hover) {
    color: #409eff;
  }
}
.selDialog {
  background: #ffffff;
  border: 1px solid #e4e4ee;
  border-radius: 6px;
  height: 400px;

  .fatherSearch {
    padding: 0 10px;
    box-sizing: border-box;
  }
  :deep(.fatherSearch .el-input__inner) {
    background: url("~@/assets/images/shu_ic_sousuo.png") no-repeat 5px center;
    background-size: 14px;
    text-indent: 14px;
    padding-left: 15px;
  }
  :deep(.selectTags) {
    display: block;
    height: 32px;
    line-height: 32px;
    background: #f0f2f5;
    border: none;
    color: #303133;
    margin-bottom: 10px;
  }
  :deep(.selectTags .el-icon-close) {
    float: right;
    top: 9px;
    background: #9eabbe;
    color: #fff;
    width: 14px;
    height: 14px;
    font-size: 12px;
  }
}
:deep(.el-tag--default .el-tag__close) {
  float: right;
  margin-top: 8px;
}
.el-dialog__headerbtn.icon-size {
  padding-right: 20px;
  color: #909399;
}
.el-dialog__headerbtn.icon-size:hover {
  padding-right: 20px;
  color: #409eff;
}
</style>
